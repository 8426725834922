/**
 * Función que convierte un archivo a una cadena base64.
 *
 * @param {File} file - El archivo a convertir.
 * @return {Promise<string>} Una promesa que se resuelve en la cadena base64 del archivo.
 */
export const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.onload = () => resolve(reader.result)
        reader.onerror = (error) => reject(error)
        reader.readAsDataURL(file)
    })
}


/**
 * Redimensiona y comprime un archivo de imagen a un ancho y alto máximo especificado con una calidad dada.
 *
 * @param {File} file - El archivo de imagen que se va a redimensionar y comprimir.
 * @param {number} maxWidth - El ancho máximo de la imagen redimensionada.
 * @param {number} maxHeight - La altura máxima de la imagen redimensionada.
 * @param {number} quality - La calidad de la imagen comprimida, que va de 0 a 1.
 * @return {Promise<File>} Una promesa que se resuelve con el archivo de imagen comprimida.
 */
export const resizeAndCompressImage = (file, maxWidth, maxHeight, quality) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)

        reader.onload = (event) => {
            const img = new Image()

            img.src = event.target.result

            img.onload = () => {
                const canvas = document.createElement("canvas")
                const ctx = canvas.getContext("2d")
                let newWidth = img.width
                let newHeight = img.height

                if (img.width > maxWidth || img.height > maxHeight) {
                    const aspectRatio = img.width / img.height
                    if (img.width > maxWidth) {
                        newWidth = maxWidth
                        newHeight = newWidth / aspectRatio
                    }
                    if (newHeight > maxHeight) {
                        newHeight = maxHeight
                        newWidth = newHeight * aspectRatio
                    }
                }

                canvas.width = newWidth
                canvas.height = newHeight

                ctx.drawImage(img, 0, 0, newWidth, newHeight)
                canvas.toBlob(
                    (blob) => {
                        const compressedFile = new File([blob], file.name, {
                            type: "image/jpeg",
                        })
                        resolve(compressedFile)
                    },
                    "image/jpeg",
                    quality
                )
            }

            img.onerror = (error) => {
                reject(error)
            }
        }

        reader.onerror = (error) => {
            reject(error)
        }
    })
}