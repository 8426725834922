import * as Actions from "../../actions/app"

const initialState = {

  loading_provider_letters: false,
  provider_letters: null,
  summary_provider_letters: null,
  total_provider_letters: 0,

  loading_provider_credit_letters: false,
  provider_credit_letters: null,

  loading_crud_provider_credit_letter: false,
  crud_provider_credit_letter: false,

  loading_finish_register_provider_credit_letters: false,
  finish_register_provider_credit_letters: false,
}

const providerCreditLettersReducer = function (state = initialState, action) {
  switch (action.type) {
    case Actions.GET_PROVIDER_LETTERS: {
      return {
        ...state,
        loading_provider_letters: action.payload.loading,
        provider_letters: typeof action.payload.detalles === 'undefined'
          ? state.provider_letters
          : action.payload.detalles,
        summary_provider_letters: action.payload.resumen === 'undefined'
          ? state.summary_provider_letters
          : action.payload.resumen,
        total_provider_letters: typeof action.payload.totalRegistros === 'undefined'
          ? state.total_provider_letters
          : action.payload.totalRegistros,
      }
    }

    case Actions.GET_PROVIDER_CREDIT_LETTERS: {
      return {
        ...state,
        loading_provider_credit_letters: action.payload.loading,
        provider_credit_letters: action.payload.data
      }
    }

    case Actions.CRUD_PROVIDER_CREDIT_LETTER: {
      return {
        ...state,
        loading_crud_provider_credit_letter: action.payload.loading,
        crud_provider_credit_letter: action.payload.crud
      }
    }

    case Actions.FINISH_REGISTER_PROVIDER_CREDIT_LETTERS: {
      return {
        ...state,
        loading_finish_register_provider_credit_letters: action.payload.loading,
        finish_register_provider_credit_letters: action.payload.crud
      }
    }

    default: {
      return state
    }
  }
}
export default providerCreditLettersReducer
