import * as Actions from "../../actions/app"

const initialState = {

  banks: null,

  loading_providers_with_credits: false,
  providers_with_credits: null,

  loading_provider_credits: false,
  provider_credits: null,

  loading_crud_provider_credit: false,
  crud_provider_credit: false,

  loading_provider_payments: false,
  provider_payments: null,
  total_provider_payments: 0,

  loading_recent_provider_payments: false,
  recent_provider_payments: null,

  loading_crud_provider_payment: false,
  crud_provider_payment: false,
}

const providerCreditsReducer = function (state = initialState, action) {
  switch (action.type) {
    case Actions.GET_BANKS: {
      return {
        ...state,
        banks: action.payload
      }
    }

    case Actions.GET_PROVIDERS_WITH_CREDITS: {
      return {
        ...state,
        loading_providers_with_credits: action.payload.loading,
        providers_with_credits: action.payload.data ?? state.providers_with_credits,
      }
    }

    case Actions.GET_PROVIDER_CREDITS: {
      return {
        ...state,
        loading_provider_credits: action.payload.loading,
        provider_credits: action.payload.data,
      }
    }

    case Actions.CRUD_PROVIDER_CREDIT: {
      return {
        ...state,
        loading_crud_provider_credit: action.payload.loading,
        crud_provider_credit: action.payload.crud,
      }
    }

    case Actions.GET_PROVIDER_PAYMENTS: {
      return {
        ...state,
        loading_provider_payments: action.payload.loading,
        provider_payments: action.payload.detalles ?? state.provider_payments,
        total_provider_payments: action.payload.totalRegistros ?? state.total_provider_payments,
      }
    }

    case Actions.CRUD_PROVIDER_PAYMENT: {
      return {
        ...state,
        loading_crud_provider_payment: action.payload.loading,
        crud_provider_payment: action.payload.crud,
      }
    }

    case Actions.GET_RECENT_PROVIDER_PAYMENTS: {
      return {
        ...state,
        loading_recent_provider_payments: action.payload.loading,
        recent_provider_payments: action.payload.data,
      }
    }

    default: {
      return state
    }
  }
}
export default providerCreditsReducer
