import * as Actions from "../../actions/app"

const initialState = {

  loading_orders: false,
  orders: null,
  total_orders: 0,

  loading_order: false,
  order: null,

  loading_crud_order_header: false,
  crud_order_header: false,
  data_crud_order_header: null,

  loading_crud_order: false,
  crud_order: false,
  data_crud_order: null,

  loading_crud_order_item: false,
  crud_order_item: false,

  loading_order_items: false,
  order_items: null,

  loading_finish_order_detail: false,
  finish_order_detail: false,

}

const orderReducer = function (state = initialState, action) {
  switch (action.type) {
    case Actions.GET_ORDERS: {
      return {
        ...state,
        loading_orders: action.payload.loading,
        orders: typeof action.payload.detalles === 'undefined'
          ? state.orders
          : action.payload.detalles,
        total_orders: typeof action.payload.totalRegistros === 'undefined'
          ? state.total_orders
          : action.payload.totalRegistros,
      }
    }

    case Actions.GET_ORDER: {
      return {
        ...state,
        loading_order: action.payload.loading,
        order: action.payload.data,
      }
    }

    case Actions.CRUD_ORDER_HEADER: {
      return {
        ...state,
        loading_crud_order_header: action.payload.loading,
        crud_order_header: action.payload.crud,
        data_crud_order_header: action.payload.data,
      }
    }

    case Actions.CRUD_ORDER: {
      return {
        ...state,
        loading_crud_order: action.payload.loading,
        crud_order: action.payload.crud,
      }
    }

    case Actions.CRUD_ORDER_ITEM: {
      return {
        ...state,
        loading_crud_order_item: action.payload.loading,
        crud_order_item: action.payload.crud
      }
    }

    case Actions.GET_ORDER_ITEMS: {
      return {
        ...state,
        loading_order_items: action.payload.loading,
        order_items: action.payload.data
      }
    }

    case Actions.FINISH_ORDER_DETAIL: {
      return {
        ...state,
        loading_finish_order_detail: action.payload.loading,
        finish_order_detail: action.payload.crud,
      }
    }

    default: {
      return state
    }
  }
}

export default orderReducer
