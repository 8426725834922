import axios from 'axios'
import { toast } from 'react-toastify'
import * as Actions from 'components/auth/store/actions'

export const GET_PROVIDER_LETTERS = "[PROVIDER CREDIT LETTERS] GET PROVIDER LETTERS"

export const GET_PROVIDER_CREDIT_LETTERS = "[PROVIDER CREDIT LETTERS] GET PROVIDER CREDIT LETTERS"

export const CRUD_PROVIDER_CREDIT_LETTER = "[PROVIDER CREDIT LETTERS] SAVE PROVIDER CREDIT LETTER"

export const FINISH_REGISTER_PROVIDER_CREDIT_LETTERS = "[PROVIDER CREDIT LETTERS] FINISH REGISTER PROVIDER CREDIT LETTERS"

export function getProviderLetters(
  { page = 1, pageSize = 10, providerId = 0, startDate = '', endDate = '', status = '', search = '', }
) {
  const request = axios.get(`${process.env.REACT_APP_API_URL}/api/letra-credito-proveedor/obtener-letras-proveedor`, {
    params: {
      page,
      por_pagina: pageSize,
      proveedor_id: providerId,
      fecha_inicio: startDate,
      fecha_fin: endDate,
      estado: status,
      busqueda: search,
    }
  })
  return (dispatch) => {
    dispatch({ type: GET_PROVIDER_LETTERS, payload: { loading: true } })
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      dispatch({ type: GET_PROVIDER_LETTERS, payload: { loading: false, ...response.data } })
    }).catch((error) => {
      dispatch({ type: GET_PROVIDER_LETTERS, payload: { loading: false } })
      console.log(error);
    })
  }
}

export function getProviderCreditLetters({ creditId = 0, status = '' }) {
  const request = axios.get(
    `${process.env.REACT_APP_API_URL}/api/letra-credito-proveedor/obtener-letras-credito`, {
    params: {
      idcredito: creditId,
      estado: status,
    }
  })
  return (dispatch) => {
    dispatch({ type: GET_PROVIDER_CREDIT_LETTERS, payload: { loading: true } })
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }

      dispatch({ type: GET_PROVIDER_CREDIT_LETTERS, payload: { loading: false, data: response.data.detalles } })

      setTimeout(() => {
        dispatch({ type: GET_PROVIDER_CREDIT_LETTERS, payload: { loading: false, data: null } })
      }, 50)

    }).catch((error) => {
      dispatch({ type: GET_PROVIDER_CREDIT_LETTERS, payload: { loading: false, data: null } })
      toast.error("Error al obtener las letras de crédito")
      console.log(error)
    })
  }
}

export function generateProviderCreditLetters(form) {
  const request = axios.post(`${process.env.REACT_APP_API_URL}/api/letra-credito-proveedor/generar-letras`, form)
  return (dispatch) => {
    dispatch({ type: CRUD_PROVIDER_CREDIT_LETTER, payload: { loading: true } })
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }

      if (response.data.status === 400) {
        dispatch({ type: CRUD_PROVIDER_CREDIT_LETTER, payload: { loading: false, crud: false } })
        toast.error(response.data.mensaje)
        return
      }

      dispatch({ type: CRUD_PROVIDER_CREDIT_LETTER, payload: { loading: false, crud: true } })
      toast.success(response.data.mensaje)

      setTimeout(() => {
        dispatch({ type: CRUD_PROVIDER_CREDIT_LETTER, payload: { loading: false, crud: false } })
      }, 50)

    }).catch((error) => {
      dispatch({ type: CRUD_PROVIDER_CREDIT_LETTER, payload: { loading: false, crud: false } })
      toast.error("Error al generar las letras de crédito")
      console.log(error)
    })
  }
}

export function saveProviderCreditLetter(from) {
  const request = axios.post(`${process.env.REACT_APP_API_URL}/api/letra-credito-proveedor/guardar-letra`, from)
  return (dispatch) => {
    dispatch({ type: CRUD_PROVIDER_CREDIT_LETTER, payload: { loading: true } })
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }

      if (response.data.status === 400) {
        dispatch({ type: CRUD_PROVIDER_CREDIT_LETTER, payload: { loading: false, crud: false } })
        toast.error(response.data.mensaje)
        return
      }

      dispatch({ type: CRUD_PROVIDER_CREDIT_LETTER, payload: { loading: false, crud: true } })
      toast.success(response.data.mensaje)

      setTimeout(() => {
        dispatch({ type: CRUD_PROVIDER_CREDIT_LETTER, payload: { loading: false, crud: false } })
      }, 50)

    }).catch((error) => {
      dispatch({ type: CRUD_PROVIDER_CREDIT_LETTER, payload: { loading: false, crud: false } })
      toast.error("Error al guardar la letra")
      console.log(error)
    })
  }
}

export function deleteProviderCreditLetter(from) {
  const request = axios.post(`${process.env.REACT_APP_API_URL}/api/letra-credito-proveedor/eliminar-letra`, from)
  return (dispatch) => {
    dispatch({ type: CRUD_PROVIDER_CREDIT_LETTER, payload: { loading: true } })
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }

      if (response.data.status === 400) {
        dispatch({ type: CRUD_PROVIDER_CREDIT_LETTER, payload: { loading: false, crud: false } })
        toast.error(response.data.mensaje)
        return
      }

      dispatch({ type: CRUD_PROVIDER_CREDIT_LETTER, payload: { loading: false, crud: true } })
      toast.success(response.data.mensaje)

      setTimeout(() => {
        dispatch({ type: CRUD_PROVIDER_CREDIT_LETTER, payload: { loading: false, crud: false } })
      }, 50)

    }).catch((error) => {
      dispatch({ type: CRUD_PROVIDER_CREDIT_LETTER, payload: { loading: false, crud: false } })
      toast.error("Error al eliminar la letra")
      console.log(error)
    })
  }
}

export function finishRegisterProviderCreditLetters(from) {
  const request = axios.post(`${process.env.REACT_APP_API_URL}/api/letra-credito-proveedor/finalizar-registro-letras`, from)
  return (dispatch) => {
    dispatch({ type: FINISH_REGISTER_PROVIDER_CREDIT_LETTERS, payload: { loading: true } })
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }

      if (response.data.status === 400) {
        dispatch({ type: FINISH_REGISTER_PROVIDER_CREDIT_LETTERS, payload: { loading: false, crud: false } })
        toast.error(response.data.mensaje)
        return
      }

      dispatch({ type: FINISH_REGISTER_PROVIDER_CREDIT_LETTERS, payload: { loading: false, crud: true } })
      toast.success(response.data.mensaje)

      setTimeout(() => {
        dispatch({ type: FINISH_REGISTER_PROVIDER_CREDIT_LETTERS, payload: { loading: false, crud: false } })
      }, 50)

    }).catch((error) => {
      dispatch({ type: FINISH_REGISTER_PROVIDER_CREDIT_LETTERS, payload: { loading: false, crud: false } })
      toast.error("Error al finalizar el registro de letras")
      console.log(error)
    })
  }
}