import * as Actions from "../../actions/app"

const initialState = {

  all_users: null,

  loading_crud_user: false,
  crud_user: false,

  validate_user_to_modify_prices: 'init',

  loading_user_series: false,
  user_series: null,

  loading_crud_user_serie: false,
  crud_user_serie: false

}

const usersReducer = function (state = initialState, action) {
  switch (action.type) {
    case Actions.GET_ALL_USERS: {
      return {
        ...state,
        all_users: action.payload
      }
    }

    case Actions.GET_USERS: {
      return {
        ...state,
        loading_users: action.payload.loading,
        users: action.payload.detalles ?? state.sales,
        total_users: action.payload.totalRegistros ?? state.total_sales,
      }
    }

    case Actions.CRUD_USER: {
      return {
        ...state,
        loading_crud_user: action.payload.loading,
        crud_user: action.payload.crud,
      }
    }

    case Actions.VALIDATE_USER_TO_MODIFY_PRICES: {
      return {
        ...state,
        validate_user_to_modify_prices: action.payload,
      }
    }

    case Actions.GET_USER_SERIES: {
      return {
        ...state,
        loading_user_series: action.payload.loading,
        user_series: action.payload.data,
      }
    }

    case Actions.CRUD_USER_SERIE: {
      return {
        ...state,
        loading_crud_user_serie: action.payload.loading,
        crud_user_serie: action.payload.crud,
      }
    }

    default: {
      return state
    }
  }
}
export default usersReducer
