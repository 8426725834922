import React from 'react';
import { AuthRoles } from 'components/auth';

export const CustomerCreditsConfig = {
  auth: AuthRoles.admin,
  routes: [
    {
      path: `${process.env.PUBLIC_URL}/customer_credits`,
      component: React.lazy(() => import('./CustomerCredits')),
    },
  ],
};
