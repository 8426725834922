import * as Actions from "../../actions/app"

const initialState = {

  loading_openings: false,
  openings: null,
  total_openings: 0,

  loading_crud_opening: false,
  crud_opening: false,

  loading_products_sold_opening: false,
  products_sold_opening: null,

  loading_totals_formspay_opening: false,
  totals_formspay_opening: null,

  loading_opening_incomes_and_expenses: false,
  opening_incomes_and_expenses: null,

}

const manageCashReducer = function (state = initialState, action) {
  switch (action.type) {
    case Actions.GET_OPENINGS: {
      return {
        ...state,
        loading_openings: action.payload.loading,
        openings: typeof action.payload.detalles === 'undefined'
          ? state.openings
          : action.payload.detalles,
        total_openings: typeof action.payload.totalRegistros === 'undefined'
          ? state.total_openings
          : action.payload.totalRegistros,
      }
    }

    case Actions.CRUD_OPENING: {
      return {
        ...state,
        loading_crud_opening: action.payload.loading,
        crud_opening: action.payload.crud,
      }
    }

    case Actions.GET_OPENING_INCOMES_AND_EXPENSES: {
      return {
        ...state,
        loading_opening_incomes_and_expenses: action.payload.loading,
        opening_incomes_and_expenses: action.payload.data
      }
    }

    default: {
      return state
    }
  }
}
export default manageCashReducer
