import * as Actions from '../../actions/app';

const initialState = {
  loading_inventory_products: false,
  inventory_products: null,
  total_inventory_products: 0,

  loading_crud_inventory_product: false,
  crud_inventory_product: false,
};

const inventoryReducer = function (state = initialState, action) {
  switch (action.type) {
    case Actions.GET_INVENTORY_PRODUCTS: {
      return {
        ...state,
        loading_inventory_products: action.payload.loading,
        inventory_products: action.payload.detalles ?? state.inventory_products,
        total_inventory_products: action.payload.totalRegistros ?? state.total_inventory_products,
      }
    }

    case Actions.CRUD_INVENTORY_PRODUCT: {
      return {
        ...state,
        loading_crud_inventory_product: action.payload.loading,
        crud_inventory_product: action.payload.crud,
      };
    }

    default: {
      return state;
    }
  }
};

export default inventoryReducer;
