import axios from 'axios';
import { toast } from 'react-toastify';
import { urlPdfs } from 'constants/index';
import * as Actions from 'components/auth/store/actions';

export const GET_ORDERS = '[ORDERS] GET ORDERS';

export const GET_ORDER = '[ORDERS] GET ORDER';

export const CRUD_ORDER_HEADER = '[ORDERS] CRUD ORDER HEADER';

export const CRUD_ORDER = '[ORDERS] CRUD ORDER';

export const CRUD_ORDER_ITEM = '[ORDERS] CRUD ORDER ITEM';

export const GET_ORDER_ITEMS = '[ORDERS] GET ORDER ITEMS';

export const FINISH_ORDER_DETAIL = '[ORDERS] FINISH ORDER DETAIL';

export function getOrders(
  { page = 1, pageSize = 10, startDate = '', endDate = '', status = '', search = '', skipUser = false }
) {
  const request = axios.get(`${process.env.REACT_APP_API_URL}/api/pedido/registros`, {
    params: {
      page,
      por_pagina: pageSize,
      fecha_inicio: startDate,
      fecha_fin: endDate,
      estado: status,
      busqueda: search,
      omitir_usuario: skipUser
    }
  })
  return (dispatch) => {
    dispatch({ type: GET_ORDERS, payload: { loading: true } })
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      dispatch({ type: GET_ORDERS, payload: { loading: false, ...response.data } })
    }).catch((error) => {
      dispatch({ type: GET_ORDERS, payload: { loading: false } })
      console.log(error)
    })
  }
}

export function getOrder({ orderId }) {
  const request = axios.get(
    `${process.env.REACT_APP_API_URL}/api/pedido-obtener?idpedido=${orderId}`
  );
  return (dispatch) => {
    dispatch({ type: GET_ORDER, payload: { loading: true } });
    request
      .then((response) => {
        if (parseInt(response.data.status) === 404) {
          if (localStorage.getItem('access_token')) {
            localStorage.removeItem('access_token');
            delete axios.defaults.headers.common['Authorization'];
            return dispatch(Actions.logoutUser());
          }
          return;
        }
        dispatch({
          type: GET_ORDER,
          payload: { loading: false, data: response.data.detalles },
        });
        setTimeout(() => {
          dispatch({
            type: GET_ORDER,
            payload: { loading: false, data: null },
          });
        }, 50);
      })
      .catch((error) => {
        dispatch({ type: GET_ORDER, payload: { loading: false, data: null } });
        toast.error('Error inesperado, no se pudo obtener el pedido');
        console.log(error);
      });
  };
}

export function saveOrderHeader(form) {
  const request = axios.post(
    `${process.env.REACT_APP_API_URL}/api/pedido/guardar-cabecera`,
    form
  );
  return (dispatch) => {
    dispatch({ type: CRUD_ORDER_HEADER, payload: { loading: true } });
    request
      .then((response) => {
        if (parseInt(response.data.status) === 404) {
          if (localStorage.getItem('access_token')) {
            localStorage.removeItem('access_token');
            delete axios.defaults.headers.common['Authorization'];
            return dispatch(Actions.logoutUser());
          }
          return;
        }
        if (response.data.status === 200) {
          dispatch({
            type: CRUD_ORDER_HEADER,
            payload: {
              loading: false,
              crud: true,
              data: response.data.detalles,
            },
          });
          toast.success(response.data.mensaje);
        } else {
          toast.error(response.data.mensaje);
        }
        setTimeout(() => {
          dispatch({
            type: CRUD_ORDER_HEADER,
            payload: { loading: false, crud: false, data: null },
          });
        }, 50);
      })
      .catch((error) => {
        dispatch({
          type: CRUD_ORDER_HEADER,
          payload: { loading: false, crud: false, data: null },
        });
        toast.error('Error inesperado. No se pudo generar el pedido');
        console.log(error);
      });
  };
}

export function saveOrderItem(form) {
  const request = axios.post(
    `${process.env.REACT_APP_API_URL}/api/pedido-detalle/guardar-item`,
    form
  );
  return (dispatch) => {
    dispatch({ type: CRUD_ORDER_ITEM, payload: { loading: true } });
    request
      .then((response) => {
        if (parseInt(response.data.status) === 404) {
          if (localStorage.getItem('access_token')) {
            localStorage.removeItem('access_token');
            delete axios.defaults.headers.common['Authorization'];
            return dispatch(Actions.logoutUser());
          }
          return;
        }
        if (parseInt(response.data.status) === 200) {
          dispatch({
            type: CRUD_ORDER_ITEM,
            payload: { loading: false, crud: true },
          });
          toast.success(response.data.mensaje);
        } else {
          toast.error(response.data.mensaje);
        }
        setTimeout(() => {
          dispatch({
            type: CRUD_ORDER_ITEM,
            payload: { loading: false, crud: false },
          });
        }, 50);
      })
      .catch((error) => {
        dispatch({
          type: CRUD_ORDER_ITEM,
          payload: { loading: false, crud: false },
        });
        toast.error('Error inesperado. No se pudo guardar el item');
        console.log(error);
      });
  };
}

export function getOrderItems({ orderId = 0 }) {
  const request = axios.get(
    `${process.env.REACT_APP_API_URL}/api/pedido-detalle/items?idpedido=${orderId}`
  );
  return (dispatch) => {
    dispatch({ type: GET_ORDER_ITEMS, payload: { loading: true } });
    request
      .then((response) => {
        if (parseInt(response.data.status) === 404) {
          if (localStorage.getItem('access_token')) {
            localStorage.removeItem('access_token');
            delete axios.defaults.headers.common['Authorization'];
            return dispatch(Actions.logoutUser());
          }
          return;
        }

        dispatch({
          type: GET_ORDER_ITEMS,
          payload: { loading: false, data: response.data.detalles },
        });

        setTimeout(() => {
          dispatch({
            type: GET_ORDER_ITEMS,
            payload: { loading: false, data: null },
          });
        }, 50);
      })
      .catch((error) => {
        dispatch({
          type: GET_ORDER_ITEMS,
          payload: { loading: false, data: null },
        });
        toast.error(
          'Error inesperado. No se pudo obtener los items del pedido'
        );
        console.log(error);
      });
  };
}

export function deleteOrderItem(form) {
  const request = axios.post(
    `${process.env.REACT_APP_API_URL}/api/pedido-detalle/eliminar-item`,
    form
  );
  return (dispatch) => {
    dispatch({ type: CRUD_ORDER_ITEM, payload: { loading: true } });
    request
      .then((response) => {
        if (parseInt(response.data.status) === 404) {
          if (localStorage.getItem('access_token')) {
            localStorage.removeItem('access_token');
            delete axios.defaults.headers.common['Authorization'];
            return dispatch(Actions.logoutUser());
          }
          return;
        }
        if (parseInt(response.data.status) === 200) {
          dispatch({
            type: CRUD_ORDER_ITEM,
            payload: { loading: false, crud: true },
          });
          toast.success(response.data.mensaje);
        } else {
          toast.error(response.data.mensaje);
        }
        setTimeout(() => {
          dispatch({
            type: CRUD_ORDER_ITEM,
            payload: { loading: false, crud: false },
          });
        }, 50);
      })
      .catch((error) => {
        dispatch({
          type: CRUD_ORDER_ITEM,
          payload: { loading: false, crud: false },
        });
        toast.error('Error inesperado. No se pudo eliminar el item');
        console.log(error);
      });
  };
}

export function finishOrderDetail(form) {
  const request = axios.post(
    `${process.env.REACT_APP_API_URL}/api/pedido-detalle/finalizar-items`,
    form
  );
  return (dispatch) => {
    dispatch({ type: FINISH_ORDER_DETAIL, payload: { loading: true } });
    request
      .then((response) => {
        if (parseInt(response.data.status) === 404) {
          if (localStorage.getItem('access_token')) {
            localStorage.removeItem('access_token');
            delete axios.defaults.headers.common['Authorization'];
            return dispatch(Actions.logoutUser());
          }
          return;
        }
        if (parseInt(response.data.status) === 200) {
          dispatch({
            type: FINISH_ORDER_DETAIL,
            payload: { loading: false, crud: true },
          });
          if (!!response.data.detalles) window.open(`${urlPdfs.pedidoTicket}/${response.data.detalles}`);
          toast.success(response.data.mensaje);
        } else {
          toast.error(response.data.mensaje);
        }
        setTimeout(() => {
          dispatch({
            type: FINISH_ORDER_DETAIL,
            payload: { loading: false, crud: false },
          });
        }, 50);
      })
      .catch((error) => {
        dispatch({
          type: FINISH_ORDER_DETAIL,
          payload: { loading: false, crud: false },
        });
        toast.error(
          'Error inesperado. No se pudo finalizar el detalle del pedido'
        );
        console.log(error);
      });
  };
}

export function deleteOrder(form) {
  const request = axios.post(
    `${process.env.REACT_APP_API_URL}/api/pedido/eliminar-pedido`,
    form
  );
  return (dispatch) => {
    dispatch({ type: CRUD_ORDER, payload: { loading: true } });
    toast.info('Eliminando pedido...', {
      toastId: 'deleteOrder',
      autoClose: false,
    });
    request
      .then((response) => {
        if (parseInt(response.data.status) === 404) {
          if (localStorage.getItem('access_token')) {
            localStorage.removeItem('access_token');
            delete axios.defaults.headers.common['Authorization'];
            return dispatch(Actions.logoutUser());
          }
          return;
        }
        if (parseInt(response.data.status) === 200) {
          dispatch({
            type: CRUD_ORDER,
            payload: { loading: false, crud: true },
          });
          toast.success(response.data.mensaje, {
            toastId: 'deleteOrder',
            autoClose: 5000,
          });
        } else {
          toast.error(response.data.mensaje, {
            toastId: 'deleteOrder',
            autoClose: 5000,
          });
        }
        setTimeout(() => {
          dispatch({
            type: CRUD_ORDER,
            payload: { loading: false, crud: false },
          });
        }, 50);
      })
      .catch((error) => {
        dispatch({
          type: CRUD_ORDER,
          payload: { loading: false, crud: false },
        });
        toast.error('Error inesperado, no se pudo eliminar el pedido', {
          toastId: 'deleteOrder',
        });
        console.log(error);
      });
  };
}

export function saveOrderFromProforma(form) {
  const request = axios.post(
    `${process.env.REACT_APP_API_URL}/api/pedido/guardar-desde-proforma`,
    form
  );
  return (dispatch) => {
    dispatch({ type: CRUD_ORDER, payload: { loading: true } });
    request
      .then((response) => {
        if (parseInt(response.data.status) === 404) {
          if (localStorage.getItem('access_token')) {
            localStorage.removeItem('access_token');
            delete axios.defaults.headers.common['Authorization'];
            return dispatch(Actions.logoutUser());
          }
          return;
        }
        if (response.data.status === 200) {
          dispatch({
            type: CRUD_ORDER,
            payload: { loading: false, crud: true },
          });
          toast.success(response.data.mensaje);
        } else {
          toast.error(response.data.mensaje);
        }
        return setTimeout(() => {
          dispatch({
            type: CRUD_ORDER,
            payload: { loading: false, crud: false },
          });
        }, 50);
      })
      .catch((error) => {
        dispatch({
          type: CRUD_ORDER,
          payload: { loading: false, crud: false },
        });
        toast.error('Error inesperado. No se pudo guardar el pedido');
        console.log(error);
      });
  };
}

export function finishOrder(form) {
  const request = axios.post(
    `${process.env.REACT_APP_API_URL}/api/pedido/finalizar-pedido`,
    form
  );
  return (dispatch) => {
    dispatch({ type: CRUD_ORDER, payload: { loading: true } });
    toast.info('Finalizando pedido...', {
      toastId: 'finishOrder',
      autoClose: false,
    });
    request
      .then((response) => {
        if (parseInt(response.data.status) === 404) {
          if (localStorage.getItem('access_token')) {
            localStorage.removeItem('access_token');
            delete axios.defaults.headers.common['Authorization'];
            return dispatch(Actions.logoutUser());
          }
          return;
        }
        if (parseInt(response.data.status) === 200) {
          dispatch({
            type: CRUD_ORDER,
            payload: { loading: false, crud: true },
          });
          window.open(`${urlPdfs.pedidoTicket}/${response.data.detalles}`);
          toast.success(response.data.mensaje, {
            toastId: 'finishOrder',
            autoClose: 5000,
          });
        } else {
          toast.error(response.data.mensaje, {
            toastId: 'finishOrder',
            autoClose: 5000,
          });
        }
        setTimeout(() => {
          dispatch({
            type: CRUD_ORDER,
            payload: { loading: false, crud: false },
          });
        }, 50);
      })
      .catch((error) => {
        dispatch({
          type: CRUD_ORDER,
          payload: { loading: false, crud: false },
        });
        toast.error('Error inesperado, no se pudo finalizar el pedido', {
          toastId: 'finishOrder',
        });
        console.log(error);
      });
  };
}

export function sendOrderByMail(form) {
  const request = axios.post(
    `${process.env.REACT_APP_API_URL}/api/enviapedidocorreo`,
    form
  );
  return (dispatch) => {
    toast.info('Enviando comprobante por correo...', {
      toastId: 'mssgCpe',
      autoClose: false,
    });
    request
      .then((response) => {
        if (parseInt(response.data.status) === 404) {
          if (localStorage.getItem('access_token')) {
            localStorage.removeItem('access_token');
            delete axios.defaults.headers.common['Authorization'];
            return dispatch(Actions.logoutUser());
          }
          return;
        }
        if (response.data.status == 200) {
          toast.update('mssgCpe', {
            render: response.data.mensaje,
            type: 'success',
            autoClose: 5000,
          });
        } else {
          console.log(response.data.detalles);
          toast.update('mssgCpe', {
            render: response.data.mensaje,
            type: 'error',
            autoClose: 5000,
          });
        }
      })
      .catch((error) => {
        console.log(error);
        toast.update('mssgCpe', {
          render: error.message,
          type: 'error',
          autoClose: 5000,
        });
      });
  };
}
