/**
 * Enfoca suavemente el primer elemento de entrada con el nombre especificado y lo pone en foco.
 * @param {string} inputName - El valor del atributo name del elemento de entrada que se desea enfocar.
 * @return {void}
 */
export const focusInputName = (inputName = '') => {
  if (!inputName) {
    window.scrollTo(0, 0)
    return
  }
  const inputs = document.getElementsByName(inputName)
  if (inputs.length > 0) {
    const input = inputs[0]
    input.scrollIntoView({ behavior: 'smooth', block: 'center' })
    input.focus()
  }
  return
}

export const focusInputId = (inputId = '') => {
  if (!inputId) {
    window.scrollTo(0, 0)
    return
  }
  const input = document.getElementById(inputId)
  if (input) {
    input.scrollIntoView({ behavior: 'smooth', block: 'center' })
    input.focus()
  }
  return
}