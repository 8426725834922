import { message } from "antd";
import { setUserData } from './user.actions';
import authService from "services/authService";

export const LOGIN_ERROR = '[LOGIN] LOGIN_ERROR';
export const LOGIN_SUCCESS = '[LOGIN] LOGIN_SUCCESS';
export const LOGIN_LOADER = '[LOGIN] LOGIN_LOADER';

export function submitLogin(username, password) {
    return (dispatch) => {
        dispatch({
            type: LOGIN_LOADER
        });
        authService.signInWithUsernameAndPassword(username, password)
            .then((user) => {
                const tUser = [
                    user
                ]

                tUser.role = "admin";

                dispatch(setUserData(tUser));

                if (!!user) message.success(`Bienvenido(a), ${user.name.split(' ').slice(0, 2).join(' ')}`);

                return dispatch({
                    type: LOGIN_SUCCESS
                });
            }
            )
            .catch(error => {
                return dispatch({
                    type: LOGIN_ERROR,
                    payload: error
                });
            })
    };
}