import axios from "axios"
import { toast } from 'react-toastify'
import { sendCreditNote } from "./manage_cpe.actions"
import { urlPdfs } from "constants/index"
import * as Actions from 'components/auth/store/actions'

export const GET_LIST_DISCREPANCIES = "[CREDIT_NOTE] GET LIST DISCREPANCIES"

export const GET_CREDIT_NOTES = "[CREDIT_NOTE] GET LIST CREDIT NOTE"

export const SAVE_CREDIT_NOTE = "[CREDIT_NOTE] SAVE CREDIT NOTE"

export function getDiscrepancies() {
  const request = axios.get(`${process.env.REACT_APP_API_URL}/api/tdiscrepancias`)
  return dispatch =>
    request.then(response => {
      if (parseInt(response.data.status) === 404) {
        if ((localStorage.getItem('access_token'))) {
          localStorage.removeItem('access_token')
          delete axios.defaults.headers.common['Authorization']
          return dispatch(Actions.logoutUser())
        }
        return
      }
      return dispatch({ type: GET_LIST_DISCREPANCIES, payload: response.data.detalles })
    })
}

export function getCreditNotes(
  { page = 1, pageSize = 10, startDate = '', endDate = '', status = '', search = '', skipUser = false }
) {
  const request = axios.get(`${process.env.REACT_APP_API_URL}/api/nota-credito/registros`, {
    params: {
      page,
      por_pagina: pageSize,
      fecha_inicio: startDate,
      fecha_fin: endDate,
      estado: status,
      busqueda: search,
      omitir_usuario: skipUser
    }
  })
  return (dispatch) => {
    dispatch({ type: GET_CREDIT_NOTES, payload: { loading: true } })
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      dispatch({ type: GET_CREDIT_NOTES, payload: { loading: false, ...response.data } })
    }).catch((error) => {
      dispatch({ type: GET_CREDIT_NOTES, payload: { loading: false } })
      console.log(error);
    })
  }
}

export function saveCreditNote(form) {
  const request = axios.post(`${process.env.REACT_APP_API_URL}/api/rnotacredito`, form)
  return dispatch => {
    dispatch({ type: SAVE_CREDIT_NOTE, payload: { saved: false, loading: true } })
    toast.info("Guardando nota de crédito...", { toastId: "saveCreditNote", autoClose: false })
    request.then(response => {
      if (parseInt(response.data.status) === 404) {
        if ((localStorage.getItem('access_token'))) {
          localStorage.removeItem('access_token')
          delete axios.defaults.headers.common['Authorization']
          return dispatch(Actions.logoutUser())
        }
        return
      }
      if (parseInt(response.data.status) === 200) {
        sendCreditNote({ idnota: response.data.detalles.nota_id, refresh: false })
        dispatch({ type: SAVE_CREDIT_NOTE, payload: { saved: true, loading: false } })
        toast.update("saveCreditNote", { render: response.data.mensaje, type: "success", autoClose: 1500 })
        window.open(
          `${urlPdfs.notaCreditoA4}/${response.data.detalles.nota_id}`,
          "_blank"
        )
      } else {
        toast.update("saveCreditNote", { render: response.data.mensaje, type: "error", autoClose: 5000 })
      }
      return setTimeout(() => {
        dispatch({ type: SAVE_CREDIT_NOTE, payload: { saved: false, loading: false } })
      }, 50)
    }).catch((error) => {
      console.log(error)
      toast.update("saveCreditNote", { render: error.message, type: "error", autoClose: 5000 })
      return dispatch({ type: SAVE_CREDIT_NOTE, payload: { saved: false, loading: false } })
    })
  }
}