import axios from "axios"
import { toast } from "react-toastify"
import * as Actions from "components/auth/store/actions"
import { urlPdfs } from 'constants/index'
import { sendSale } from "./manage_cpe.actions"

export const GET_DATA_TYPE_PAYMENT = "[SALES] GET ALL TYPE PAYMENT"

export const GET_DATA_TYPE_PAYMENT_FROMS = "[SALES] GET ALL TYPE PAYMENT FROMS"

export const GET_DATA_SERIES_VOUCHER = "[SALES] GET ALL DATA SERIES BY VOUCHER"

export const GET_DATA_CORRELATIVE_SERIES_VOUCHER = "[SALES] GET DATA CORRELATIVE SERIES BY VOUCHER"

export const GET_DATA_COIN_TYPE = "[SALES] GET DATA COIN TYPE"

export const GET_SALES = "[SALES] GET SALES"

export const GET_SALE = "[SALES] GET SALE"

export const CRUD_SALE = "[SALES] CRUD SALE"

export const GET_PRICE_DOLLAR_SALES = "[SALES] GET PRICE DOLLAR"

export const GET_CREDITS_BY_PERSON = "[ACCOUNTS RECEIVABLE] GET SALE CREDIT"

export const SALE_CREDIT_TRUE = "[ACCOUNTS RECEIVABLE] SALE CREDIT TRUE"

export const SAVE_CREDIT = "[ACCOUNTS RECEIVABLE] SAVE CREDIT"

export const RESET_CRUD_CREDIT = "[ACCOUNTS RECEIVABLE] RESET CRUD CREDIT"

export const GET_PERSONS_CREDITS = "[ACCOUNTS RECEIVABLE] GET PERSONS CREDITS"

export const SAVE_PAY_OF_CREDIT = "[ACCOUNTS RECEIVABLE] SAVE PAY OF CREDIT"

export const RESET_CRUD_PAY_OF_CREDIT = "[ACCOUNTS RECEIVABLE] RESET CRUD PAY OF CREDIT"

export const GET_PAYMENTS_PERSON_OF_CREDIT = "[ACCOUNTS RECEIVABLE] GET PAYMENTS PERSON OF CREDIT"

export const LOADING_PP_OF_CREDIT = "[ACCOUNTS RECEIVABLE] LOADING_PP_OF_CREDIT"

export const CANCEL_PAY_OF_CREDIT = "[ACCOUNTS RECEIVABLE] CANCEL PAY OF CREDIT"

export const CANCEL_CREDIT = "[ACCOUNTS RECEIVABLE] CANCEL CREDIT"

export function getSales(
  { page = 1, pageSize = 10, startDate = '', endDate = '', voucherType = '', status = '', search = '', skipUser = false }
) {
  const request = axios.get(`${process.env.REACT_APP_API_URL}/api/venta/registros`, {
    params: {
      page,
      por_pagina: pageSize,
      fecha_inicio: startDate,
      fecha_fin: endDate,
      tipo_comprobante: voucherType,
      estado: status,
      busqueda: search,
      omitir_usuario: skipUser
    }
  })
  return (dispatch) => {
    dispatch({ type: GET_SALES, payload: { loading: true } })
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      dispatch({ type: GET_SALES, payload: { loading: false, ...response.data } })
    }).catch((error) => {
      dispatch({ type: GET_SALES, payload: { loading: false } })
      console.log(error);
    })
  }
}

export function getSale({ saleId }) {
  const request = axios.get(`${process.env.REACT_APP_API_URL}/api/venta/obtener-venta?idventa=${saleId}`)
  return (dispatch) => {
    dispatch({ type: GET_SALE, payload: { loading: true } })
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      dispatch({ type: GET_SALE, payload: { loading: false, data: response.data.detalles } })
      setTimeout(() => {
        dispatch({ type: GET_SALE, payload: { loading: false, data: null } })
      }, 50)
    }).catch((error) => {
      dispatch({ type: GET_SALE, payload: { loading: false, data: null } })
      toast.error("Error inesperado, no se pudo obtener la venta")
      console.log(error)
    })
  }
}

export function saveSale(form) {
  const request = axios.post(`${process.env.REACT_APP_API_URL}/api/venta/registrar-venta`, form)
  return (dispatch) => {
    dispatch({ type: CRUD_SALE, payload: { loading: true } })
    toast.info("Registrando venta...", { toastId: 'saveSale', autoClose: false })
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      if (parseInt(response.data.status) === 200) {
        sendSale({ idventa: response.data.detalles.facturacion_id, refresh: false })

        dispatch({ type: CRUD_SALE, payload: { crud: true, loading: false } })

        toast.success(response.data.mensaje, { toastId: 'saveSale', autoClose: 5000 })

        if (response.data.detalles.tipo_pago_id == 2) {
          dispatch({ type: SALE_CREDIT_TRUE, payload: response.data.detalles })
        }

        if (response.data.detalles.tipo_pago_id != 2 && !!response.data.finalizado
          && response.data.detalles.aplica_despacho == 0) {
          window.open(`${urlPdfs.ventaTicket}/${response.data.detalles.facturacion_id}`);
        }

      } else {
        toast.error(response.data.mensaje, { toastId: "saveSale", autoClose: 5000 })
      }

      setTimeout(() => {
        dispatch({ type: CRUD_SALE, payload: { crud: false, loading: false } })
      }, 50)

    }).catch((error) => {
      dispatch({ type: CRUD_SALE, payload: { crud: false, loading: false } })
      toast.error("Error inesperado. No se pudo registrar la venta", { toastId: 'saveSale', autoClose: 5000 })
      console.log(CRUD_SALE, error)
    })
  }
}

export function updateSale(form) {
  const request = axios.post(`${process.env.REACT_APP_API_URL}/api/venta/actualizar-venta`, form)
  return (dispatch) => {
    dispatch({ type: CRUD_SALE, payload: { loading: true } })
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      if (response.data.status === 200) {
        dispatch({ type: CRUD_SALE, payload: { loading: false, crud: true, } })
        toast.success(response.data.mensaje)
      } else {
        toast.error(response.data.mensaje)
      }
      setTimeout(() => {
        dispatch({ type: CRUD_SALE, payload: { loading: false, crud: false } })
      }, 50)
    }).catch((error) => {
      dispatch({ type: CRUD_SALE, payload: { loading: false, crud: false } })
      toast.error("Error inesperado. No se pudo atualizar la venta")
      console.log(error)
    })
  }
}

export function finishSale(form) {
  const request = axios.post(`${process.env.REACT_APP_API_URL}/api/venta/finalizar-venta`, form)
  return (dispatch) => {
    dispatch({ type: CRUD_SALE, payload: { loading: true } })
    toast.info('Finalizando venta...', { toastId: 'finishSale', autoClose: false })
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      if (parseInt(response.data.status) === 200) {
        dispatch({ type: CRUD_SALE, payload: { loading: false, crud: true } })
        window.open(`${urlPdfs.ventaTicket}/${response.data.detalles}`)
        toast.success(response.data.mensaje, { toastId: 'finishSale', autoClose: 5000 })
      } else {
        toast.error(response.data.mensaje, { toastId: 'finishSale', autoClose: 5000 })
      }
      setTimeout(() => {
        dispatch({ type: CRUD_SALE, payload: { loading: false, crud: false } })
      }, 50)
    }).catch((error) => {
      dispatch({ type: CRUD_SALE, payload: { loading: false, crud: false } })
      toast.error("Error inesperado, no se pudo finalizar la venta", { toastId: 'finishSale' })
      console.log(error)
    })
  }
}

export function deleteSale(form) {
  const request = axios.post(`${process.env.REACT_APP_API_URL}/api/venta/anular-venta`, form)
  return (dispatch) => {
    dispatch({ type: CRUD_SALE, payload: { loading: true } })
    toast.info('Anulando venta...', { toastId: 'deleteSale', autoClose: false })
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      if (parseInt(response.data.status) === 200) {
        dispatch({ type: CRUD_SALE, payload: { loading: false, crud: true } })
        toast.success(response.data.mensaje, { toastId: 'deleteSale', autoClose: 5000 })
      } else {
        toast.error(response.data.mensaje, { toastId: 'deleteSale', autoClose: 5000 })
      }
      setTimeout(() => {
        dispatch({ type: CRUD_SALE, payload: { loading: false, crud: false } })
      }, 50)
    }).catch((error) => {
      dispatch({ type: CRUD_SALE, payload: { loading: false, crud: false } })
      toast.error("Error inesperado, no se pudo anular la venta", { toastId: 'deleteSale' })
      console.log(error)
    })
  }
}

export function getPriceDollar() {
  const request = axios.get(`${process.env.REACT_APP_API_URL}/api/preciodollar`)
  return (dispatch) =>
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {

          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      return dispatch({
        type: GET_PRICE_DOLLAR_SALES,
        payload: response.data.detalles === null ? [] : response.data.detalles,
      })
    })
}

export function getCoinTypes() {
  const request = axios.get(`${process.env.REACT_APP_API_URL}/api/tipomoneda`)
  return (dispatch) =>
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      return dispatch({
        type: GET_DATA_COIN_TYPE,
        payload: response.data.detalles === null ? [] : response.data.detalles,
      })
    })
}

export function getCorrelativeSeriesVoucher(form) {
  const request = axios.get(
    `${process.env.REACT_APP_API_URL}/api/seriexcomprobantexusuario/${form.idUser}/${form.idVoucher}/${form.idSerie}`
  )
  return (dispatch) =>
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {

          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      dispatch({
        type: GET_DATA_CORRELATIVE_SERIES_VOUCHER,
        payload: response.data.detalles === null ? {} : response.data.detalles,
      })
      setTimeout(() => {
        dispatch({ type: GET_DATA_CORRELATIVE_SERIES_VOUCHER, payload: {} })
      }, 50)
    })
}

export function getAllSeriesVoucher(form) {
  const request = axios.get(`${process.env.REACT_APP_API_URL}/api/seriexcomprobante/${form.idUser}/${form.idVoucher}`)
  return (dispatch) =>
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {

          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      return dispatch({
        type: GET_DATA_SERIES_VOUCHER,
        payload: response.data.detalles === null ? [] : response.data.detalles,
      })
    })
}

export function getAllTypesPayments() {
  const request = axios.get(`${process.env.REACT_APP_API_URL}/api/tipopago`)
  return (dispatch) =>
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      return dispatch({
        type: GET_DATA_TYPE_PAYMENT,
        payload: response.data.detalles === null ? [] : response.data.detalles,
      })
    })
}

export function getPaymentForms(form) {
  const request = axios.get(`${process.env.REACT_APP_API_URL}/api/tipoformapago/${form}`)
  return (dispatch) =>
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      return dispatch({
        type: GET_DATA_TYPE_PAYMENT_FROMS,
        payload: response.data.detalles === null ? [] : response.data.detalles,
      })
    })
}

export function saveCredit(form) {
  const request = axios.post(`${process.env.REACT_APP_API_URL}/api/rcredito`, form)
  return (dispatch) =>
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          console.log(response.data.detalles)
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      if (parseInt(response.data.status) === 200) {
        toast.success(response.data.mensaje, { toastId: "saveCredit", autoClose: 5000 })
      } else {
        toast.error(response.data.mensaje)
      }

      dispatch({
        type: SAVE_CREDIT,
        payload: response.data.detalles ? response.data.detalles : null,
      })

      setTimeout(() => {
        dispatch({ type: RESET_CRUD_CREDIT })
      }, 50)
    })
}

export function getPersonsWithCredits(userId = 0) {
  const request = axios.get(`${process.env.REACT_APP_API_URL}/api/gpersonascreditos/${userId}`)
  return (dispatch) =>
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      return dispatch({ type: GET_PERSONS_CREDITS, payload: response.data.detalles })
    })
}

export function getCreditsByPersonId(personId, userId = 0) {
  const request = axios.get(`${process.env.REACT_APP_API_URL}/api/gcreditospersona?idpersona=${personId}&idusuario=${userId}`)
  return (dispatch) => {
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      return dispatch({ type: GET_CREDITS_BY_PERSON, payload: response.data.detalles })
    })
  }
}

export function savePayOfCredit(form) {
  const request = axios.post(`${process.env.REACT_APP_API_URL}/api/rcpago`, form)

  return (dispatch) =>
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          console.log(response.data.detalles)
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      if (parseInt(response.data.status) === 200) {
        toast.success(response.data.mensaje)
        window.open(`${urlPdfs.notaPagoTicket}/${response.data.detalles.idpago}`, "_blank")

      } else {
        toast.error(response.data.mensaje)
      }

      dispatch({
        type: SAVE_PAY_OF_CREDIT,
        payload: response.data.detalles ? response.data.detalles : null,
      })

      setTimeout(() => {
        dispatch({
          type: RESET_CRUD_PAY_OF_CREDIT,
        })
      }, 50)
    })
}

export function getPaymentsPersonOfCredit(personId, userId, useLoading = true) {
  const request = axios.get(`${process.env.REACT_APP_API_URL}/api/gcpagospersona?idpersona=${personId}&idusuario=${userId}`)
  return (dispatch) => {
    if (useLoading) dispatch({ type: LOADING_PP_OF_CREDIT, payload: true })
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      return dispatch({ type: GET_PAYMENTS_PERSON_OF_CREDIT, payload: response.data.detalles })
    })
  }
}

export function cancelPayOfCredit(payId) {
  const request = axios.get(`${process.env.REACT_APP_API_URL}/api/ccpago/${payId}`)
  return (dispatch) =>
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      if (parseInt(response.data.status) === 200) {
        toast.success(response.data.mensaje)
      } else {
        toast.error(response.data.mensaje)
      }

      dispatch({ type: CANCEL_PAY_OF_CREDIT, payload: true })

      setTimeout(() => {
        dispatch({ type: CANCEL_PAY_OF_CREDIT, payload: false })
      }, 50)

    })
}

export function cancelCredit(creditId) {
  const request = axios.get(
    `${process.env.REACT_APP_API_URL}/api/ccredito/${creditId}`
  )

  return (dispatch) =>
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          console.log(response.data.detalles)
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      if (parseInt(response.data.status) === 200) {
        toast.success(response.data.mensaje)
      } else {
        toast.error(response.data.mensaje, { autoClose: 5000 })
      }

      dispatch({ type: CANCEL_CREDIT, payload: true })

      setTimeout(() => {
        dispatch({ type: CANCEL_CREDIT, payload: false })
      }, 50)

    })
}