import * as Actions from "../../actions/app"

const initialState = {

  purchases: null,
  total_pages_purchases: 1,
  total_registers_purchases: 0,

  loading_purchase: false,
  purchase: null,

  loading_crud_purchase_header: false,
  crud_purchase_header: false,
  data_crud_purchase_header: null,

  loading_crud_purchase: false,
  crud_purchase: false,
  data_crud_purchase: null,

  loading_crud_purchase_item: false,
  crud_purchase_item: false,

  loading_purchase_items: false,
  purchase_items: null,

  loading_finish_purchase_detail: false,
  finish_purchase_detail: false,

}

const purchasesReducer = function (state = initialState, action) {
  switch (action.type) {
    case Actions.GET_PURCHASES: {
      return {
        ...state,
        loading_purchases: action.payload.loading,
        purchases: typeof action.payload.detalles === 'undefined'
          ? state.purchases
          : action.payload.detalles,
        total_purchases: typeof action.payload.totalRegistros === 'undefined'
          ? state.total_purchases
          : action.payload.totalRegistros,
      }
    }

    case Actions.GET_PURCHASE: {
      return {
        ...state,
        loading_purchase: action.payload.loading,
        purchase: action.payload.data,
      }
    }

    case Actions.CRUD_PURCHASE_HEADER: {
      return {
        ...state,
        loading_crud_purchase_header: action.payload.loading,
        crud_purchase_header: action.payload.crud,
        data_crud_purchase_header: action.payload.data,
      }
    }

    case Actions.CRUD_PURCHASE: {
      return {
        ...state,
        loading_crud_purchase: action.payload.loading,
        crud_purchase: action.payload.crud,
      }
    }

    case Actions.CRUD_PURCHASE_ITEM: {
      return {
        ...state,
        loading_crud_purchase_item: action.payload.loading,
        crud_purchase_item: action.payload.crud
      }
    }

    case Actions.GET_PURCHASE_ITEMS: {
      return {
        ...state,
        loading_purchase_items: action.payload.loading,
        purchase_items: action.payload.data
      }
    }

    case Actions.FINISH_PURCHASE_DETAIL: {
      return {
        ...state,
        loading_finish_purchase_detail: action.payload.loading,
        finish_purchase_detail: action.payload.crud,
      }
    }

    default: {
      return state
    }
  }
}
export default purchasesReducer
