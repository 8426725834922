import axios from 'axios'
import { toast } from 'react-toastify'
import * as Actions from 'components/auth/store/actions'

export const GET_CUSTOMERS_WITH_CREDITS = "[CUSTOMER CREDITS] GET CUSTOMERS WITH CREDITS"

export const GET_CUSTOMER_CREDITS = "[CUSTOMER CREDITS] GET CUSTOMER CREDITS"

export const CRUD_CUSTOMER_CREDIT = "[CUSTOMER CREDITS] CRUD CUSTOMER CREDIT"

export function getCustomersWithCredits() {
  const request = axios.get(`${process.env.REACT_APP_API_URL}/api/credito-cliente/clientes-con-creditos`)
  return (dispatch) => {
    dispatch({ type: GET_CUSTOMERS_WITH_CREDITS, payload: { loading: true } })
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      dispatch({ type: GET_CUSTOMERS_WITH_CREDITS, payload: { loading: false, data: response.data.detalles } })
    }).catch((error) => {
      dispatch({ type: GET_CUSTOMERS_WITH_CREDITS, payload: { loading: false, data: [] } })
      toast.error("No se pudo obtener a los clientes con créditos")
      console.log(error)
    })
  }
}

export function getCustomerCredits({ customerId = 0, status = '' }) {
  const request = axios.get(`${process.env.REACT_APP_API_URL}/api/credito-cliente/creditos-por-cliente`, {
    params: { cliente_id: customerId, estado: status }
  })
  return (dispatch) => {
    dispatch({ type: GET_CUSTOMER_CREDITS, payload: { loading: true } })
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }

      dispatch({ type: GET_CUSTOMER_CREDITS, payload: { loading: false, data: response.data.detalles } })

      setTimeout(() => {
        dispatch({ type: GET_CUSTOMER_CREDITS, payload: { loading: false, data: null } })
      }, 50)
      return
    }).catch((error) => {
      dispatch({ type: GET_CUSTOMER_CREDITS, payload: { loading: false, data: null } })
      toast.error("No se pudo obtener a los creditos del cliente")
      console.log(error);
    })
  }
}

export function deleteCustomerCredit(form) {
  const request = axios.post(`${process.env.REACT_APP_API_URL}/api/credito-cliente/anular-credito`, form)
  return (dispatch) => {
    dispatch({ type: CRUD_CUSTOMER_CREDIT, payload: { loading: true } })
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }

      if (parseInt(response.data.status) !== 200) {
        dispatch({ type: CRUD_CUSTOMER_CREDIT, payload: { loading: false, crud: false } })
        toast.error(response.data.mensaje)
        return
      }

      dispatch({ type: CRUD_CUSTOMER_CREDIT, payload: { loading: false, crud: true } })
      toast.success(response.data.mensaje)

      setTimeout(() => {
        dispatch({ type: CRUD_CUSTOMER_CREDIT, payload: { loading: false, crud: false } })
      }, 50)
      return
    }).catch((error) => {
      dispatch({ type: CRUD_CUSTOMER_CREDIT, payload: { loading: false, crud: false } })
      toast.error("No se pudo anular el crédito")
      console.log(error);
    })
  }
}