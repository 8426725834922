import axios from 'axios'
import { toast } from 'react-toastify'
import * as Actions from 'components/auth/store/actions'

export const GET_BANKS = "[BANKS] GET BANKS"

export const GET_PROVIDERS_WITH_CREDITS = "[PROVIDER CREDITS] GET PROVIDERS WITH CREDITS"

export const GET_PROVIDER_CREDITS = "[PROVIDER CREDITS] GET PROVIDER CREDITS"

export const CRUD_PROVIDER_CREDIT = "[PROVIDER CREDITS] CRUD PROVIDER CREDIT"

export const GET_PROVIDER_PAYMENTS = "[PROVIDER PAYMENTS] GET PROVIDER PAYMENTS"

export const GET_RECENT_PROVIDER_PAYMENTS = "[PROVIDER PAYMENTS] GET RECENT PROVIDER PAYMENTS"

export const CRUD_PROVIDER_PAYMENT = "[PROVIDER PAYMENTS] CRUD PROVIDER PAYMENT"

export function getBanks() {
  const request = axios.get(`${process.env.REACT_APP_API_URL}/api/bancos`)
  return (dispatch) =>
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      dispatch({ type: GET_BANKS, payload: response.data.detalles })
    }).catch((error) => {
      dispatch({ type: GET_BANKS, payload: [] })
      toast.error("No se pudo obtener las entidades bancarias")
      console.log(error)
    })
}

export function getProvidersWithCredits() {
  const request = axios.get(`${process.env.REACT_APP_API_URL}/api/credito-proveedor/proveedores-con-creditos`)
  return (dispatch) => {
    dispatch({ type: GET_PROVIDERS_WITH_CREDITS, payload: { loading: true } })
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      dispatch({ type: GET_PROVIDERS_WITH_CREDITS, payload: { loading: false, data: response.data.detalles } })
    }).catch((error) => {
      dispatch({ type: GET_PROVIDERS_WITH_CREDITS, payload: { loading: false, data: [] } })
      toast.error("No se pudo obtener a los proveedores con créditos")
      console.log(error)
    })
  }
}

export function getProviderCredits({ providerId = 0, status = '' }) {
  const request = axios.get(`${process.env.REACT_APP_API_URL}/api/credito-proveedor/creditos-por-proveedor`, {
    params: { proveedor_id: providerId, estado: status }
  })
  return (dispatch) => {
    dispatch({ type: GET_PROVIDER_CREDITS, payload: { loading: true } })
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }

      dispatch({ type: GET_PROVIDER_CREDITS, payload: { loading: false, data: response.data.detalles } })

      setTimeout(() => {
        dispatch({ type: GET_PROVIDER_CREDITS, payload: { loading: false, data: null } })
      }, 50)
      return
    }).catch((error) => {
      dispatch({ type: GET_PROVIDER_CREDITS, payload: { loading: false, data: null } })
      toast.error("No se pudo obtener a los creditos del proveedor")
      console.log(error);
    })
  }
}

export function deleteProviderCredit(form) {
  const request = axios.post(`${process.env.REACT_APP_API_URL}/api/credito-proveedor/anular-credito`, form)
  return (dispatch) => {
    dispatch({ type: CRUD_PROVIDER_CREDIT, payload: { loading: true } })
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }

      if (parseInt(response.data.status) !== 200) {
        dispatch({ type: CRUD_PROVIDER_CREDIT, payload: { loading: false, crud: false } })
        toast.error(response.data.mensaje)
        return
      }

      dispatch({ type: CRUD_PROVIDER_CREDIT, payload: { loading: false, crud: true } })
      toast.success(response.data.mensaje)

      setTimeout(() => {
        dispatch({ type: CRUD_PROVIDER_CREDIT, payload: { loading: false, crud: false } })
      }, 50)
      return
    }).catch((error) => {
      dispatch({ type: CRUD_PROVIDER_CREDIT, payload: { loading: false, crud: false } })
      toast.error("No se pudo anular el crédito")
      console.log(error);
    })
  }
}

export function getProviderPayments(
  { page = 1, pageSize = 10, providerId = 0, startDate = '', endDate = '', status = '', search = '', }
) {
  const request = axios.get(`${process.env.REACT_APP_API_URL}/api/pago-proveedor/obtener-pagos-por-proveedor`, {
    params: {
      page,
      por_pagina: pageSize,
      proveedor_id: providerId,
      fecha_inicio: startDate,
      fecha_fin: endDate,
      estado: status,
      busqueda: search,
    }
  })
  return (dispatch) => {
    dispatch({ type: GET_PROVIDER_PAYMENTS, payload: { loading: true } })
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      dispatch({ type: GET_PROVIDER_PAYMENTS, payload: { loading: false, ...response.data } })
    }).catch((error) => {
      dispatch({ type: GET_PROVIDER_PAYMENTS, payload: { loading: false } })
      console.log(error);
    })
  }
}

export function getRecentProviderPayments({ providerId = 0, limit = 5 }) {
  const request = axios.get(`${process.env.REACT_APP_API_URL}/api/pago-proveedor/obtener-pagos-recientes-por-proveedor`, {
    params: { proveedor_id: providerId, limite: limit }
  })
  return (dispatch) => {
    dispatch({ type: GET_RECENT_PROVIDER_PAYMENTS, payload: { loading: true } })
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }

      dispatch({ type: GET_RECENT_PROVIDER_PAYMENTS, payload: { loading: false, data: response.data.detalles } })

      setTimeout(() => {
        dispatch({ type: GET_RECENT_PROVIDER_PAYMENTS, payload: { loading: false, data: null } })
      }, 50)
      return
    }).catch((error) => {
      dispatch({ type: GET_RECENT_PROVIDER_PAYMENTS, payload: { loading: false, data: null } })
      toast.error("No se pudo obtener los pagos recientes del proveedor")
      console.log(error);
    })
  }
}

export function saveProviderPayment(form) {
  const request = axios.post(`${process.env.REACT_APP_API_URL}/api/pago-proveedor/guardar-pago`, form)
  return (dispatch) => {
    dispatch({ type: CRUD_PROVIDER_PAYMENT, payload: { loading: true } })
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }

      if (parseInt(response.data.status) !== 200) {
        dispatch({ type: CRUD_PROVIDER_PAYMENT, payload: { loading: false, crud: false } })
        toast.error(response.data.mensaje)
        return
      }

      dispatch({ type: CRUD_PROVIDER_PAYMENT, payload: { loading: false, crud: true } })
      toast.success(response.data.mensaje)

      setTimeout(() => {
        dispatch({ type: CRUD_PROVIDER_PAYMENT, payload: { loading: false, crud: false } })
      }, 50)
      return
    }).catch((error) => {
      dispatch({ type: CRUD_PROVIDER_PAYMENT, payload: { loading: false, crud: false } })
      toast.error("No se pudo registrar el pago")
      console.log(error);
    })
  }
}

export function deleteProviderPayment(form) {
  const request = axios.post(`${process.env.REACT_APP_API_URL}/api/pago-proveedor/anular-pago`, form)
  return (dispatch) => {
    dispatch({ type: CRUD_PROVIDER_PAYMENT, payload: { loading: true } })
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }

      if (parseInt(response.data.status) !== 200) {
        dispatch({ type: CRUD_PROVIDER_PAYMENT, payload: { loading: false, crud: false } })
        toast.error(response.data.mensaje)
        return
      }

      dispatch({ type: CRUD_PROVIDER_PAYMENT, payload: { loading: false, crud: true } })
      toast.success(response.data.mensaje)

      setTimeout(() => {
        dispatch({ type: CRUD_PROVIDER_PAYMENT, payload: { loading: false, crud: false } })
      }, 50)
      return
    }).catch((error) => {
      dispatch({ type: CRUD_PROVIDER_PAYMENT, payload: { loading: false, crud: false } })
      toast.error("No se pudo anular el pago")
      console.log(error);
    })
  }
}