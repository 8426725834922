import React from 'react';
import { AuthRoles } from 'components/auth';

export const ProviderCreditsConfig = {
  auth: AuthRoles.admin,
  routes: [
    {
      path: `${process.env.PUBLIC_URL}/provider_credits`,
      component: React.lazy(() => import('./ProviderCredits')),
    },
  ],
};
