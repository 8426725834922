import axios from "axios"
import { toast } from "react-toastify"
import * as Actions from "components/auth/store/actions"

export const GET_PERSON_DOCUMENT_TYPES = "[PEOPLE] GET PERSON DOCUMENT TYPES"

export const GET_PEOPLE = "[PEOPLE] GET PEOPLE"

export const CRUD_PERSON = "[PEOPLE] CRUD PERSON"

export const SEARCH_PEOPLE = "[PEOPLE] FIND PEOPLE"

export const GET_PERSON = "[PEOPLE] GET PERSON"

export const GET_DEFAULT_PERSON = "[PEOPLE] GET DEFAULT PERSON"

export const GET_PERSON_BY_DNI = "[PEOPLE] GET PERSON BY DNI"

export const GET_PERSON_BY_RUC = "[PEOPLE] GET PERSON BY RUC"


export function getPersonDocumentTypes() {
  const request = axios.get(`${process.env.REACT_APP_API_URL}/api/tipodocumento`)
  return (dispatch) =>
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      dispatch({ type: GET_PERSON_DOCUMENT_TYPES, payload: response.data.detalles })
    })
}

export function savePerson(form) {
  const request = axios.post(`${process.env.REACT_APP_API_URL}/api/persona-guardar`, form)
  return (dispatch) => {
    dispatch({ type: CRUD_PERSON, payload: { loading: true } })
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      if (parseInt(response.data.status) === 200) {
        dispatch({ type: CRUD_PERSON, payload: { loading: false, crud: true, data: response.data.detalles } })
        toast.success(response.data.mensaje)
      } else {
        toast.error(response.data.mensaje)
      }
      setTimeout(() => {
        dispatch({ type: CRUD_PERSON, payload: { loading: false, crud: false, data: null } })
      }, 50)
    }).catch((err) => {
      dispatch({ type: CRUD_PERSON, payload: { loading: false, crud: false, data: null } })
      toast.error("Error inesperado, no se pudo guardar a la persona")
      console.log(err)
    })
  }
}

export function getPeople({ registerType = 0 }) {
  const request = axios.get(`${process.env.REACT_APP_API_URL}/api/persona/registros?tiporegistro=${registerType}`)
  return (dispatch) => {
    dispatch({ type: GET_PEOPLE, payload: { loading: true } })
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      dispatch({ type: GET_PEOPLE, payload: { loading: false, data: response.data.detalles } })
      setTimeout(() => {
        dispatch({ type: GET_PEOPLE, payload: { loading: false, data: null } })
      }, 50)
    }).catch((error) => {
      dispatch({ type: GET_PEOPLE, payload: { loading: false, data: null } })
      toast.error("Error inesperado, no se pudo obtener las personas")
      console.log(error)
    })
  }
}

export function searchPeople({ registerType = '', limit = 50, search = '' }) {
  if (!search || !search?.trim()) {
    return (dispatch) => {
      dispatch({ type: SEARCH_PEOPLE, payload: { loading: false, data: [] } });
    };
  }

  const request = axios.get(`${process.env.REACT_APP_API_URL}/api/persona/buscar-personas`, {
    params: {
      tipo_registro: registerType,
      limite: limit,
      busqueda: search
    }
  })
  return (dispatch) => {
    dispatch({ type: SEARCH_PEOPLE, payload: { loading: true } })
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }

      dispatch({ type: SEARCH_PEOPLE, payload: { loading: false, data: response.data.detalles } })
      return

    }).catch((error) => {
      dispatch({ type: SEARCH_PEOPLE, payload: { loading: false, data: [] } })
      toast.error("Error interno. No se pudo consultar las personas")
      console.log(error)
      return
    })
  }
}

export function deletePerson(form) {
  const request = axios.post(`${process.env.REACT_APP_API_URL}/api/persona-eliminar`, form)
  return (dispatch) => {
    dispatch({ type: CRUD_PERSON, payload: { loading: true } })
    toast.info('Borrando persona...', { toastId: 'deletePerson', autoClose: false })
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      if (parseInt(response.data.status) === 200) {
        dispatch({ type: CRUD_PERSON, payload: { loading: false, crud: true } })
        toast.success(response.data.mensaje, { toastId: 'deletePerson', autoClose: 5000 })
      } else {
        toast.error(response.data.mensaje, { toastId: 'deletePerson', autoClose: 5000 })
      }
      setTimeout(() => {
        dispatch({ type: CRUD_PERSON, payload: { loading: false, crud: false } })
      }, 50)
    }).catch((error) => {
      dispatch({ type: CRUD_PERSON, payload: { loading: false, crud: false } })
      toast.error("Error inesperado, no se pudo borrar a la persona", { toastId: 'deletePerson' })
      console.log(error)
    })
  }
}

export function getPerson({ personId = 0 }) {
  const request = axios.get(`${process.env.REACT_APP_API_URL}/api/persona-obtener?idpersona=${personId}`)
  return (dispatch) => {
    dispatch({ type: GET_PERSON, payload: { loading: true } })
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      dispatch({ type: GET_PERSON, payload: { loading: false, data: response.data.detalles } })
      setTimeout(() => {
        dispatch({ type: GET_PERSON, payload: { loading: false, data: null } })
      }, 50)
    }).catch((error) => {
      dispatch({ type: GET_PERSON, payload: { loading: false, data: null } })
      toast.error("Error inesperado, no se pudo consultar una persona")
      console.log(error)
    })
  }
}

export function getDefaultPerson() {
  const request = axios.get(`${process.env.REACT_APP_API_URL}/api/persona-por-defecto`)
  return (dispatch) => {
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      dispatch({ type: GET_DEFAULT_PERSON, payload: response.data.detalles })
    }).catch((error) => {
      dispatch({ type: GET_DEFAULT_PERSON, payload: null })
      toast.info("No se pudo consultar una persona predeterminada")
      console.log(error)
    })
  }
}

export function getPersonByDni({ dni = '' }) {
  const request = axios.get(`${process.env.REACT_APP_API_URL}/api/persona-dni?dni=${dni}`)
  return (dispatch) => {
    dispatch({ type: GET_PERSON_BY_DNI, payload: { loading: true } })
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }

      if (parseInt(response.data.status) === 200) {
        dispatch({ type: GET_PERSON_BY_DNI, payload: { loading: false, data: response.data.detalles } })
      } else {
        toast.error(response.data.mensaje)
      }
      setTimeout(() => {
        dispatch({ type: GET_PERSON_BY_DNI, payload: { loading: false, data: null } })
      }, 50)
    }).catch((error) => {
      dispatch({ type: GET_PERSON_BY_DNI, payload: { loading: false, data: null } })
      toast.error("Error inesperado, no se pudo obtener la persona")
      console.log(error)
    })
  }
}

export function getPersonByRuc({ ruc = '' }) {
  const request = axios.get(`${process.env.REACT_APP_API_URL}/api/persona-ruc?ruc=${ruc}`)
  return (dispatch) => {
    dispatch({ type: GET_PERSON_BY_RUC, payload: { loading: true } })
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      if (parseInt(response.data.status) === 200) {
        dispatch({ type: GET_PERSON_BY_RUC, payload: { loading: false, data: response.data.detalles } })
      } else {
        toast.error(response.data.mensaje)
      }
      setTimeout(() => {
        dispatch({ type: GET_PERSON_BY_RUC, payload: { loading: false, data: null } })
      }, 50)
    }).catch((error) => {
      dispatch({ type: GET_PERSON_BY_RUC, payload: { loading: false, data: null } })
      toast.error("Error inesperado, no se pudo obtener la persona")
      console.log(error)
    })
  }
}