
export const userTypes = [
  {
    value: 1,
    label: 'Usuario'
  },
  {
    value: 2,
    label: 'Empleado'
  }
]

export const userRoles = [
  {
    value: 'super-admin',
    label: 'Super-Administrador'
  },
  {
    value: 'admin',
    label: 'Administrador'
  },
  {
    value: 'atm',
    label: 'Cajero'
  },
  {
    value: 'seller',
    label: 'Vendedor'
  },
  {
    value: 'tech',
    label: 'Técnico'
  },
  {
    value: 'claimer',
    label: 'Reclamos'
  },
  {
    value: 'admin-complaints',
    label: 'Administrador-Reclamos'
  }
]
