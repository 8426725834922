export function createDebouncedSearch(searchFunction, wait = 500) {
  let timeout = null

  const debouncedSearch = (params = {}) => {
    if (timeout) clearTimeout(timeout)

    return new Promise(() => {
      timeout = setTimeout(() => {
        if (!params || !Object.keys(params).length || !params.search?.trim()) return

        searchFunction(params)
      }, wait)
    })
  }

  debouncedSearch.cancel = () => {
    if (timeout) clearTimeout(timeout)
  }

  return debouncedSearch
}
