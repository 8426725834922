import { Select, AutoComplete, DatePicker } from 'antd';

const { RangePicker } = DatePicker;

// props por defecto en Select
Select.defaultProps = {
  ...Select.defaultProps,
  dropdownMatchSelectWidth: false,
  placeholder: 'Seleccionar',
  notFoundContent: 'Sin opciones',
  getPopupContainer: (trigger) => trigger.parentNode,
};

// props por defecto en AutoComplete
AutoComplete.defaultProps = {
  ...AutoComplete.defaultProps,
  dropdownMatchSelectWidth: false,
  getPopupContainer: (trigger) => trigger.parentNode,
};

// props por defecto en RangePicker
RangePicker.defaultProps = {
  ...RangePicker.defaultProps,
  dropdownMatchSelectWidth: false,
  getPopupContainer: (trigger) => trigger.parentNode,
};