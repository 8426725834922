export const urlFiles = {
  productImage: `${process.env.REACT_APP_API_URL}/foto-producto`,
  productFile: `${process.env.REACT_APP_API_URL}/archivo-producto`,
}

export const urlPdfs = {
  // pdfs a4  
  ventaA4: `${process.env.REACT_APP_API_URL}/api/pdf/a4/venta`,
  reporteVentasA4: `${process.env.REACT_APP_API_URL}/api/pdf/a4/reporte-ventas`,
  notaCreditoA4: `${process.env.REACT_APP_API_URL}/api/pdf/a4/nota-credito`,
  proformaA4: `${process.env.REACT_APP_API_URL}/api/pdf/a4/proforma`,
  guiaA4: `${process.env.REACT_APP_API_URL}/api/pdf/a4/guia-remision`,
  entradaA4: `${process.env.REACT_APP_API_URL}/api/pdf/a4/entrada`,
  salidaA4: `${process.env.REACT_APP_API_URL}/api/pdf/a4/salida`,
  cierreCajaA4: `${process.env.REACT_APP_API_URL}/api/pdf/a4/cierre-caja`,
  ordenServicioA4: `${process.env.REACT_APP_API_URL}/api/pdf/a4/orden-servicio`,
  inventarioOrdenServicioA4: `${process.env.REACT_APP_API_URL}/api/pdf/a4/inventario-orden-servicio`,
  reportePrestamosClienteA4: `${process.env.REACT_APP_API_URL}/api/pdf/a4/reporte-prestamos-cliente`,
  reclamacionA4: `${process.env.REACT_APP_API_URL}/api/pdf/a4/reclamacion`,

  // pdfs ticket
  ventaTicket: `${process.env.REACT_APP_API_URL}/api/pdf/ticket/venta`,
  proformaTicket: `${process.env.REACT_APP_API_URL}/api/pdf/ticket/proforma`,
  pedidoTicket: `${process.env.REACT_APP_API_URL}/api/pdf/ticket/pedido`,
  cierreCajaTicket: `${process.env.REACT_APP_API_URL}/api/pdf/ticket/cierre-caja`,
  cajaChicaTicket: `${process.env.REACT_APP_API_URL}/api/pdf/ticket/caja-chica`,
  notaPagoTicket: `${process.env.REACT_APP_API_URL}/api/pdf/ticket/nota-pago`,
  prestamoTicket: `${process.env.REACT_APP_API_URL}/api/pdf/ticket/prestamo`,
  prestamoCajaChicaTicket: `${process.env.REACT_APP_API_URL}/api/pdf/ticket/prestamo-caja-chica`,
  pagoCuotaPrestamoTicket: `${process.env.REACT_APP_API_URL}/api/pdf/ticket/pago-cuota-prestamo`,
}

export const urlExcels = {
  reporteVentas: `${process.env.REACT_APP_API_URL}/api/excel/reporte-ventas`,
}