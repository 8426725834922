import { validateIsNumber } from "../validate-is-number"

/**
 * Obtiene los precios de la unidad de medida de un producto 
 * @param {array} unitMeasuresProduct 
 * @param {number} unitMeasureId 
 * @param {object} selectedPrices 
 * @returns {array}
 */
export const getPricesProductFromUnitMeasures = (

  unitMeasuresProduct = [],

  unitMeasureId = 0,

  selectedPrices = {
    precioventa: true,
    precio1: true,
    precio2: true,
    precio3: true,
    preciocredito: true,
    preciocompra: false,
    preciocosto: false
  },

) => {

  let prices = []

  if (!validateIsNumber(unitMeasureId)) return prices

  if (!unitMeasuresProduct) return prices

  if (!unitMeasuresProduct.length) return prices

  const filteredProduct = unitMeasuresProduct.find(product => product.unidad_medida_id == unitMeasureId)

  if (!filteredProduct) return prices

  if (selectedPrices.precioventa) prices = [
    ...prices, { field: "precioventa", name: "Venta", amount: filteredProduct.precioventa }
  ]

  if (selectedPrices.precio1) prices = [
    ...prices, { field: "precio1", name: "Precio 1", amount: filteredProduct.precio1 }
  ]

  if (selectedPrices.precio2) prices = [
    ...prices, { field: "precio2", name: "Precio 2", amount: filteredProduct.precio2 }
  ]

  if (selectedPrices.precio3) prices = [
    ...prices, { field: "precio3", name: "Precio 3", amount: filteredProduct.precio3 }
  ]

  if (selectedPrices.preciocredito) prices = [
    ...prices, { field: "preciocredito", name: "Crédito", amount: filteredProduct.preciocredito }
  ]

  if (selectedPrices.preciocompra) prices = [
    ...prices, { field: "preciocompra", name: "Compra", amount: filteredProduct.preciocompra }
  ]

  if (selectedPrices.preciocosto) prices = [
    ...prices, { field: "preciocosto", name: "Costo", amount: filteredProduct.preciocosto }
  ]

  return prices.filter(price => validateIsNumber(price.amount))
}