import * as Actions from "../../actions/app"

const initialState = {

  loading_cash_receipts: false,
  cash_receipts: null,
  total_cash_receipts: 0,

  loading_cash_outlays: false,
  cash_outlays: null,
  total_cash_outlays: 0,

  loading_crud_cash_movement: false,
  crud_cash_movement: false

}

const cashMovementsReducer = function (state = initialState, action) {
  switch (action.type) {
    case Actions.GET_CASH_RECEIPTS: {
      return {
        ...state,
        loading_cash_receipts: action.payload.loading,
        cash_receipts: typeof action.payload.detalles === 'undefined'
          ? state.cash_receipts
          : action.payload.detalles,
        total_cash_receipts: typeof action.payload.totalRegistros === 'undefined'
          ? state.total_cash_receipts
          : action.payload.totalRegistros,
      }
    }

    case Actions.GET_CASH_OUTLAYS: {
      return {
        ...state,
        loading_cash_outlays: action.payload.loading,
        cash_outlays: typeof action.payload.detalles === 'undefined'
          ? state.cash_outlays
          : action.payload.detalles,
        total_cash_outlays: typeof action.payload.totalRegistros === 'undefined'
          ? state.total_cash_outlays
          : action.payload.totalRegistros,
      }
    }

    case Actions.CRUD_CASH_MOVEMENTS: {
      return {
        ...state,
        loading_crud_cash_movement: action.payload.loading,
        crud_cash_movement: action.payload.crud
      }
    }

    default: {
      return state
    }
  }
}
export default cashMovementsReducer
