import React, { useRef } from 'react'
import { getYear } from 'date-fns'
import { useForm } from 'react-hook-form'
import { Button, Card, Input } from 'antd'
import clsx from 'clsx'
import * as authActions from 'components/auth/store/actions'
import { useDispatch, useSelector } from "react-redux"
import reducer from 'components/auth/store/reducers'
import withReducer from 'store/withReducer'

const Login = () => {
  const dispatch = useDispatch()
  const login = useSelector(({ auth }) => auth.login)
  const loader = useSelector(({ auth }) => auth.login.loader)

  const formRef = useRef(null)

  // refs para los inputs visibles
  const usernameRef = useRef(null)
  const passwordRef = useRef(null)

  const { handleSubmit, register, setValue, errors } = useForm()

  const onSubmit = (data) => dispatch(
    authActions.submitLogin(data.username, data.password)
  )

  return (
    <div className="h-100 d-flex align-items-center justify-content-center">
      <div className="login-box" style={{ width: 310 }}>
        <div className="login-logo">
          <a href="/" className="fw-600">
            {process.env.REACT_APP_ESTABLISHMENT_NAME || 'Trade'}
          </a>
        </div>
        <Card
          bordered
          className="rounded-lg shadow-sm"
        >
          <p className="login-box-msg">
            Ingrese sus credenciales
          </p>

          {!!login.error && (
            <div className="alert alert-danger">
              <h5 className="alert-heading">
                <i className="icon fas fa-ban mr-2"></i>
                Mensaje del sistema
              </h5>
              {login.error}
            </div>
          )}

          <form onSubmit={handleSubmit(onSubmit)} ref={formRef}>
            <input
              type="hidden"
              name="username"
              ref={register({ required: 'Campo obligatorio' })}
            />

            <div>
              <Input
                ref={usernameRef}
                autoFocus
                className={clsx("form-control", !!errors.username && 'is-invalid')}
                placeholder="Nombre de usuario"
                onChange={() => setValue('username', usernameRef.current.input.value)}
                prefix={
                  <i className="fas fa-user mr-2" />
                }
              />
            </div>

            {!!errors.username && (
              <p className="text-danger mt-2 small">{errors.username.message}</p>
            )}

            <input
              type="hidden"
              name="password"
              ref={register({ required: 'Campo obligatorio' })}
            />

            <div className="mt-2">
              <Input.Password
                ref={passwordRef}
                className={clsx("form-control", !!errors.password && 'is-invalid')}
                placeholder="Contraseña"
                onChange={() => setValue('password', passwordRef.current.input.value)}
                prefix={
                  <i className="fas fa-lock mr-2" />
                }
              />
            </div>

            {!!errors.password && (
              <p className="text-danger mt-2 small">{errors.password.message}</p>
            )}

            <div className="mt-3">
              <Button
                type="primary"
                htmlType="submit"
                className="bg-success border-0 w-100"
                loading={loader}
              >
                Iniciar sesión
              </Button>
            </div>
          </form>

        </Card>

        <p className="mt-3 text-center">
          <em>
            &copy;{getYear(new Date())}, {process.env.REACT_APP_DEVELOPER}
          </em>
        </p>
      </div>
    </div>
  )
}

export default withReducer('auth', reducer)(Login)
