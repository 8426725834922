import * as Actions from "../../actions/app"

const initialState = {

  loading_customers_with_credits: false,
  customers_with_credits: null,

  loading_customer_credits: false,
  customer_credits: null,

  loading_crud_customer_credit: false,
  crud_customer_credit: false,

}

const customerCreditsReducer = function (state = initialState, action) {
  switch (action.type) {
    case Actions.GET_CUSTOMERS_WITH_CREDITS: {
      return {
        ...state,
        loading_customers_with_credits: action.payload.loading,
        customers_with_credits: action.payload.data ?? state.customers_with_credits,
      }
    }

    case Actions.GET_CUSTOMER_CREDITS: {
      return {
        ...state,
        loading_customer_credits: action.payload.loading,
        customer_credits: action.payload.data,
      }
    }

    case Actions.CRUD_CUSTOMER_CREDIT: {
      return {
        ...state,
        loading_crud_customer_credit: action.payload.loading,
        crud_customer_credit: action.payload.crud,
      }
    }

    default: {
      return state
    }
  }
}
export default customerCreditsReducer
